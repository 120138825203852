import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_TPM_ds_get_invoice_totalsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { invoiceIds?: number[], statusId?: number, typeId?: number, isIRA?: boolean, take?: number, minId?: number, minDate?: string, projectIdsToInclude?: number[], projectIdsToExclude?: number[] }): 
  Promise<{ result: { LineTotal?: number, MaxLineNumber?: number, TaxScheduleId?: number, InvoiceId?: number, Invoice?: { ProjectId?: number, Project?: { Owner?: { LookupCode?: string, DirectMinimum?: number } } } }[] }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { invoiceIds?: number[], statusId?: number, typeId?: number, isIRA?: boolean, take?: number, minId?: number, minDate?: string, projectIdsToInclude?: number[], projectIdsToExclude?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { LineTotal?: number, MaxLineNumber?: number, TaxScheduleId?: number, InvoiceId?: number, Invoice?: { ProjectId?: number, Project?: { Owner?: { LookupCode?: string, DirectMinimum?: number } } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { invoiceIds?: number[], statusId?: number, typeId?: number, isIRA?: boolean, take?: number, minId?: number, minDate?: string, projectIdsToInclude?: number[], projectIdsToExclude?: number[], $keys: { InvoiceId?: number, Invoice?: { Project?: { Owner?: { LookupCode?: string, DirectMinimum?: number } }, ProjectId?: number } }[] }): 
  Promise<{ result: { LineTotal?: number, MaxLineNumber?: number, TaxScheduleId?: number, InvoiceId?: number, Invoice?: { ProjectId?: number, Project?: { Owner?: { LookupCode?: string, DirectMinimum?: number } } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
