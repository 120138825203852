import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { FootPrintApiManager_DatasourceService } from './FootPrintApiManager.datasource.index';

import { app_TPM_ds_get_billing_codesService } from './app.datasource.index';
import { app_TPM_ds_get_billing_contract_line_detailsService } from './app.datasource.index';
import { app_TPM_ds_get_billing_ratesService } from './app.datasource.index';
import { app_TPM_ds_get_billing_tasksService } from './app.datasource.index';
import { app_TPM_ds_get_billing_tasks_projectsService } from './app.datasource.index';
import { app_TPM_ds_get_invoice_totalsService } from './app.datasource.index';

@Injectable({ providedIn: 'root' })
export class app_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.Invoices = this.injector.get(Invoices_DatasourceService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
    public Invoices: Invoices_DatasourceService;
    public FootPrintApiManager: FootPrintApiManager_DatasourceService;
  public app: app_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _TPM_ds_get_billing_codes: app_TPM_ds_get_billing_codesService;
  public get TPM_ds_get_billing_codes(): app_TPM_ds_get_billing_codesService {
    if(!this._TPM_ds_get_billing_codes) {
      this._TPM_ds_get_billing_codes = this.injector.get(app_TPM_ds_get_billing_codesService);
    }
    return this._TPM_ds_get_billing_codes;
  }
  private _TPM_ds_get_billing_contract_line_details: app_TPM_ds_get_billing_contract_line_detailsService;
  public get TPM_ds_get_billing_contract_line_details(): app_TPM_ds_get_billing_contract_line_detailsService {
    if(!this._TPM_ds_get_billing_contract_line_details) {
      this._TPM_ds_get_billing_contract_line_details = this.injector.get(app_TPM_ds_get_billing_contract_line_detailsService);
    }
    return this._TPM_ds_get_billing_contract_line_details;
  }
  private _TPM_ds_get_billing_rates: app_TPM_ds_get_billing_ratesService;
  public get TPM_ds_get_billing_rates(): app_TPM_ds_get_billing_ratesService {
    if(!this._TPM_ds_get_billing_rates) {
      this._TPM_ds_get_billing_rates = this.injector.get(app_TPM_ds_get_billing_ratesService);
    }
    return this._TPM_ds_get_billing_rates;
  }
  private _TPM_ds_get_billing_tasks: app_TPM_ds_get_billing_tasksService;
  public get TPM_ds_get_billing_tasks(): app_TPM_ds_get_billing_tasksService {
    if(!this._TPM_ds_get_billing_tasks) {
      this._TPM_ds_get_billing_tasks = this.injector.get(app_TPM_ds_get_billing_tasksService);
    }
    return this._TPM_ds_get_billing_tasks;
  }
  private _TPM_ds_get_billing_tasks_projects: app_TPM_ds_get_billing_tasks_projectsService;
  public get TPM_ds_get_billing_tasks_projects(): app_TPM_ds_get_billing_tasks_projectsService {
    if(!this._TPM_ds_get_billing_tasks_projects) {
      this._TPM_ds_get_billing_tasks_projects = this.injector.get(app_TPM_ds_get_billing_tasks_projectsService);
    }
    return this._TPM_ds_get_billing_tasks_projects;
  }
  private _TPM_ds_get_invoice_totals: app_TPM_ds_get_invoice_totalsService;
  public get TPM_ds_get_invoice_totals(): app_TPM_ds_get_invoice_totalsService {
    if(!this._TPM_ds_get_invoice_totals) {
      this._TPM_ds_get_invoice_totals = this.injector.get(app_TPM_ds_get_invoice_totalsService);
    }
    return this._TPM_ds_get_invoice_totals;
  }
}

