import { Inject, Injectable, Injector } from '@angular/core';


import { Invoices_invoice_reportService } from './Invoices.report.index';

@Injectable({ providedIn: 'root' })
export class Invoices_ReportService {

  constructor(
    private injector: Injector
  ) {
  }

  public Invoices: Invoices_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _invoice_report: Invoices_invoice_reportService;
  public get invoice_report(): Invoices_invoice_reportService {
    if(!this._invoice_report) {
      this._invoice_report = this.injector.get(Invoices_invoice_reportService);
    }
    return this._invoice_report;
  }
}

