import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintApiManager_homeComponent } from './FootPrintApiManager.home.component';
import { FootPrintApiManager_log_notes_reportComponent } from './FootPrintApiManager.log_notes_report.component';
import { FootPrintApiManager_transaction_reportComponent } from './FootPrintApiManager.transaction_report.component';


@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
  ) {
    super(dialog, toastr);
  }

  public FootPrintApiManager: FootPrintApiManager_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openhome(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'FootPrintApiManager',
        referenceName: 'FootPrintApiManager_home',
        component: FootPrintApiManager_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openhomeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintApiManager_homeComponent,
      'FootPrintApiManager',
      mode,
      dialogSize
    )
  }
  public openlog_notes_report(inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'log_notes_report',
        referenceName: 'FootPrintApiManager_log_notes_report',
        component: FootPrintApiManager_log_notes_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlog_notes_reportDialog(
    inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      FootPrintApiManager_log_notes_reportComponent,
      'log_notes_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentransaction_report(inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'transaction_report',
        referenceName: 'FootPrintApiManager_transaction_report',
        component: FootPrintApiManager_transaction_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentransaction_reportDialog(
    inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      FootPrintApiManager_transaction_reportComponent,
      'transaction_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'FootPrintApiManager_home') {
      const title = 'FootPrintApiManager';
      const component = FootPrintApiManager_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_log_notes_report') {
      const title = 'log_notes_report';
      const component = FootPrintApiManager_log_notes_reportComponent;
      const inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] } = { transaction_id: null, log_ids: [], message_ids: [] };
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('log_ids'))) {
        const paramValueString = params.get('log_ids');
        inParams.log_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('message_ids'))) {
        const paramValueString = params.get('message_ids');
        inParams.message_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintApiManager_transaction_report') {
      const title = 'transaction_report';
      const component = FootPrintApiManager_transaction_reportComponent;
      const inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] } = { transaction_id: null, log_ids: [], message_ids: [] };
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('log_ids'))) {
        const paramValueString = params.get('log_ids');
        inParams.log_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('message_ids'))) {
        const paramValueString = params.get('message_ids');
        inParams.message_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    return result;
  }
}
