import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Invoices_ds_get_billing_record_by_billingTaskIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { billingTaskId: number }): 
  Promise<{ result: { BillingTaskId?: number, ActualAmount?: number, ApplicableMinimumCharge?: number, ApplicableRate?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, InvoiceLineId?: number, Notes?: string, Total?: number, BillingTask?: { Amount?: number, Cancelled?: boolean, LicensePlateId?: number, LocationId?: number, LotId?: number, MaterialId?: number, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, BillingContractLine?: { BillingContractId?: number, LineNumber?: number, BillingContract?: { Id?: number } }, CancelledReasonCode?: { LookupCode?: string } }, InvoiceLine?: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, LineNumber?: number, Invoice?: { Id?: number, StatusId?: number } } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.billingTaskId)) {
      missingRequiredInParams.push('\'billingTaskId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
