import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootPrintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_inventory_snapshot_queryService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { owner?: string, project: string, warehouse?: string, material?: string, date?: string }): Promise<{ payload?: any[], messages?: any[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.project)) {
      missingRequiredInParams.push('\'project\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

