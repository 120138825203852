import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_TPM_ds_get_billing_tasksService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { projectId?: number, cancelled?: boolean, shipmentTypeId?: number, shipmentIds?: number[], cutOffDate?: string, assigned?: boolean, billingAggregationStrategyIds?: number[], hasShipment?: boolean, warehouseIds?: number[], top?: number, minCreationDate?: string, minId?: number, hasNotes?: boolean, isIRA?: boolean, projectIdsToInclude?: number[], projectIdsToExclude?: number[] }): 
  Promise<{ result: { Id?: number, Amount?: number, BillingContractLineId?: number, CreatedSysDateTime?: string, BillingRecord?: { ApplicableRate?: number } }[] }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { projectId?: number, cancelled?: boolean, shipmentTypeId?: number, shipmentIds?: number[], cutOffDate?: string, assigned?: boolean, billingAggregationStrategyIds?: number[], hasShipment?: boolean, warehouseIds?: number[], top?: number, minCreationDate?: string, minId?: number, hasNotes?: boolean, isIRA?: boolean, projectIdsToInclude?: number[], projectIdsToExclude?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, Amount?: number, BillingContractLineId?: number, CreatedSysDateTime?: string, BillingRecord?: { ApplicableRate?: number } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { projectId?: number, cancelled?: boolean, shipmentTypeId?: number, shipmentIds?: number[], cutOffDate?: string, assigned?: boolean, billingAggregationStrategyIds?: number[], hasShipment?: boolean, warehouseIds?: number[], top?: number, minCreationDate?: string, minId?: number, hasNotes?: boolean, isIRA?: boolean, projectIdsToInclude?: number[], projectIdsToExclude?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, Amount?: number, BillingContractLineId?: number, CreatedSysDateTime?: string, BillingRecord?: { ApplicableRate?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
