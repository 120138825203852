import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootPrintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_insert_logService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { level: number, process: string, notes: string }): Promise<void> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.level)) {
      missingRequiredInParams.push('\'level\'');
    }
    if (isNil(inParams.process)) {
      missingRequiredInParams.push('\'process\'');
    }
    if (isNil(inParams.notes)) {
      missingRequiredInParams.push('\'notes\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

