import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootPrintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_sftp_pullService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { connection: string, source_path: string, archive_path?: string, error_path?: string, file_extension?: string, file_name?: string, sort_asc?: boolean, transaction_id?: string }): Promise<{ messages?: any[], success?: boolean, files?: { file_name?: string, file_blob?: any }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.connection)) {
      missingRequiredInParams.push('\'connection\'');
    }
    if (isNil(inParams.source_path)) {
      missingRequiredInParams.push('\'source_path\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

