<div class="blade-wrapper">
  <div class="blade-header">
    <div class="blade-tools">
      <div class="toolContainer">
        <button mat-button [disabled]="!canExecute" class="datex-button" (click)="run()">
          <div class="button-label">
            <div class="button-icon"><i class="icon icon-ic_fluent_play_circle_20_regular"></i>
            </div>
            <div class="button-text">Execute</div>
          </div>
        </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized ">
        <div class="formdata">
          <div class="fieldset-container">
            <div class="fieldsetsContainer">
              <div class="fieldsetsGroup">
                <div class="field-container standard">
                  <div class="label-container" title="Config type">
                    <label class="datex-label" title="Config type">Config type</label>
                  </div>
                  <app-dropdown-single name="type" [ngModel]="typeValue" (ngModelChange)="typeValueChange($event)"
                    [disabled]="false" [placeholder]="'Choose a type'" [displayWith]="typeDisplayWithFn.bind(this)"
                    [options]="typeOptionsFn.bind(this)">
                  </app-dropdown-single>
                </div>
                <div class="field-container standard">
                  <div class="label-container" title="Config">
                    <label class="datex-label" title="Config">Config</label>
                  </div>
                  <app-dropdown-single #dd_config name="config" [ngModel]="configValue"
                    (ngModelChange)="configValueChange($event)" [disabled]="!typeValue"
                    [placeholder]="typeValue?'Choose a config':''" [displayWith]="configDisplayWithFn.bind(this)"
                    [options]="configOptionsFn.bind(this)">
                  </app-dropdown-single>
                </div>
                <div class="field-container standard">
                  <div class="label-container" title="Method">
                    <label class="datex-label" title="Config">Method</label>
                  </div>
                  <app-dropdown-single #dd_method name="method" [ngModel]="methodValue"
                    (ngModelChange)="methodValueChange($event)" [disabled]="!hasMethods"
                    [placeholder]="hasMethods?'Choose a method':''" [displayWith]="methodDisplayWithFn.bind(this)"
                    [options]="methodOptionsFn.bind(this)">
                  </app-dropdown-single>
                </div>
              </div>
            </div>
          </div>
          <div class="fieldsetsContainer" *ngIf="inParams">
            <div class="fieldsetsTitle">Inputs parameters</div>
            <div class="fieldsetsGroup">
              <ng-container *ngFor="let inParam of inParams; index as i">
                <!-- Bug where the control cannot have focus. 
                  Have to resize the window then suddenly you can focus on control  -->

                <div class="field-container standard">
                  <div class="label-container" title="inParam.id">
                    <label class="datex-label" title="inParam.id">{{inParam.id}}</label>
                    <span *ngIf="inParam.required" class="required-asterisk">*</span>
                    <span>({{inParam.type}}{{inParam.isCollection?'[]':''}})</span>
                  </div>
                  <input matInput type="text" class="datex-textbox" [(ngModel)]="inParam.value"
                    (ngModelChange)="onInParamChanged()" [ngModelOptions]="{updateOn: 'blur'}"
                    [required]="inParam.required" name="inParam_{{i}}" />
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="datex-tabcontrol">
          <div *ngIf="canExecute" class="dataview">
            <json-formatter [expand]="true" [json]="result"></json-formatter>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>