import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_ds_get_invoice_by_invoice_idService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { invoice_id?: number }): 
  Promise<{ result: { Id?: number, AttentionOf?: string, BillToContactId?: number, ContainsPortalVisibleAttachments?: boolean, CreatedSysDateTime?: string, CreatedSysUser?: string, DiscountDate?: string, DueDate?: string, FromContactId?: number, InvoiceDate?: string, LookupCode?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, Printed?: boolean, ReferenceCode?: string, ShipToContactId?: number, UserName?: string, Account?: { LookupCode?: string, Name?: string, Notes?: string, Type?: { Name?: string } }, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string }, Currency?: { Label?: string, ShortLabel?: string }, Status?: { Name?: string }, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } }, ReasonCode?: { Label?: string, LookupCode?: string, Name?: string }, Term?: { Description?: string, Name?: string }, Type?: { Name?: string } } }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
